<template>
    <!-- <div> -->
        <svg width="25" height="25" viewBox="0 0 360 360" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M315 64.8532V295.147C315 300.413 312.908 305.462 309.185 309.185C305.462 312.909 300.412 315 295.147 315H64.8529C59.5876 315 54.5379 312.909 50.8148 309.185C47.0916 305.462 45 300.413 45 295.147V64.8532C45 59.5879 47.0916 54.5382 50.8148 50.815C54.5379 47.0919 59.5876 45.0002 64.8529 45.0002H295.147C300.412 45.0002 305.462 47.0919 309.185 50.815C312.908 54.5382 315 59.5879 315 64.8532ZM124.412 148.236H84.7059V275.294H124.412V148.236ZM127.985 104.559C128.006 101.556 127.435 98.5775 126.305 95.7947C125.175 93.0119 123.508 90.4789 121.399 88.3404C119.29 86.2019 116.781 84.4998 114.014 83.3312C111.247 82.1626 108.277 81.5504 105.274 81.5297H104.559C98.451 81.5297 92.5934 83.956 88.2746 88.2748C83.9557 92.5937 81.5294 98.4513 81.5294 104.559C81.5294 110.667 83.9557 116.524 88.2746 120.843C92.5934 125.162 98.451 127.588 104.559 127.588C107.562 127.662 110.551 127.144 113.354 126.062C116.157 124.981 118.72 123.357 120.896 121.285C123.071 119.213 124.817 116.732 126.033 113.985C127.25 111.237 127.913 108.277 127.985 105.274V104.559ZM275.294 198.106C275.294 159.909 250.994 145.059 226.853 145.059C218.949 144.663 211.079 146.347 204.028 149.942C196.977 153.537 190.993 158.917 186.671 165.547H185.559V148.236H148.235V275.294H187.941V207.715C187.367 200.794 189.547 193.925 194.008 188.602C198.469 183.279 204.85 179.931 211.765 179.286H213.274C225.9 179.286 235.271 187.227 235.271 207.238V275.294H274.976L275.294 198.106Z" fill="white"/>
        </svg>                      
    <!-- </div> -->
</template>
  
<script>

export default {
    name: "LinkedInLogo",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor{
fill:$clr-saina-light-dark-main-bg
}

</style>